import useDataApi from '../../effects/fetcher'
import { AGGREGATE_COMPUTATION, BASE_URL } from '../../environment/globals'
import { Button, Checkbox, Empty, Icon, Radio, Row, Spin, Typography } from 'antd'
import RetryHomeRow from '../../ui_pieces/retry_home_row'
import React, { useState } from 'react'


const { Text } = Typography

function AggregateQuestion({ calculateAggregate }) {

  const [{ isLoading, isError, error, data, forceUpdateSwitch }, setForceUpdateSwitch] =
    useDataApi(BASE_URL + '/api/biweeklies/' + sessionStorage.getItem('surveyId') + '/get-aggregate-questions', [])

  const [selectedAggregateQuestions, setSelectedAggregateQuestions] = useState([])
  const [computationType, setComputationType] = useState(AGGREGATE_COMPUTATION[Object.keys(AGGREGATE_COMPUTATION)[0]])

  let questionsData = null
  let retryHandler = () => {
    setForceUpdateSwitch(!forceUpdateSwitch)
  }

  try {
    if (data.hasOwnProperty('biweekly_questions')) {
      let options = []

      data.biweekly_questions.forEach((item, index) => {
        options.push({ value: item.id, label: item.short_form })
      })
      questionsData =
        <Row style={{ height: '600px', overflowY: 'scroll' }}>

          <Checkbox.Group options={options} className={'custom-checkbox'}

                          onChange={(data) => setSelectedAggregateQuestions(data)}/>
        </Row>

    } else {
      questionsData =
        <Empty description={'No numeric questions found in this survey'}/>
    }

    // // construct aggregate computations
    // <Radio.Group onChange={onChange} defaultValue="a"  >
    //
    // </Radio.Group>
    // aggregateComputations =  for(let aggregateComputation in AGGREGATE_COMPUTATION){
    //
    // }
    console.log('agcomp', AGGREGATE_COMPUTATION, AGGREGATE_COMPUTATION[Object.keys(AGGREGATE_COMPUTATION)[0]])

  } catch (e) {
    questionsData = <
      RetryHomeRow
      showHome={false}
      retryHandler={retryHandler}
    />
  }
  return (

    <Row type={'flex'} justify={'center'} style={{ margin: '2em 1em 1em 1em' }}>


      <Spin spinning={isLoading} size={'large'}
            indicator={<Icon spin={isLoading} type={'loading'} title={'fetching data'}/>}>


        <div style={{ marginBottom: '1em' }}>
          <Text type={'secondary'} strong={true}> Select numeric questions to visualize results </Text>
        </div>


        <div style={{ marginBottom: '1em' }}>
          {!isLoading && data.hasOwnProperty('biweekly_questions') &&
          <Button title={'Calculate'} type={'primary'} style={{ marginBottom: '.5em' }}
                  onClick={() => calculateAggregate(selectedAggregateQuestions, computationType)}> Calculate</Button>}
        </div>


        <div style={{ marginBottom: '.5em' }}>
          <Radio.Group buttonStyle={'outline'} onChange={(event) => setComputationType(event.target.value)}
                       defaultValue={AGGREGATE_COMPUTATION[Object.keys(AGGREGATE_COMPUTATION)[0]]}>
            {
              Object.keys(AGGREGATE_COMPUTATION).map(key =>
                <Radio.Button  value={AGGREGATE_COMPUTATION[key]}>{key}</Radio.Button>)
            }
          </Radio.Group>
        </div>


        {!isLoading && questionsData}


      </Spin>
    </Row>


  )
}

export default AggregateQuestion