import React, { Component } from 'react'
// import { HashRouter as Router, Route } from 'react-router-dom'
import { Router } from '@reach/router'
import { enquireScreen } from 'enquire-js'

import Header from './pages/landing/Nav3'
import Footer from './pages/landing/Footer1'
import LandingHome from './pages/landing/index'
import Surveys from './pages/surveys/surveys'

import {
  Nav30DataSource,
  Footer10DataSource
} from './pages/landing/data.source'
import Dashboard from './pages/dashboard/dashboard'
import Login from './pages/dashboard/login'

let isMobile
enquireScreen(b => {
  isMobile = b
})

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile
    }
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })
  }

  render() {
    return (
      <div>
        <Header dataSource={Nav30DataSource} isMobile={this.state.isMobile}/>


        <Router>
          <LandingHome path="/"/>
          <Surveys path="surveys"/>
          <Dashboard path={'dashboard'}/>
          <Login path={'login'}/>
        </Router>
        <Footer
          dataSource={Footer10DataSource}
          isMobile={this.state.isMobile}
        />
      </div>
    )
  }
}

export default App
