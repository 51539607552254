import { useEffect, useState } from 'react'
import { GET_COOKIE } from '../environment/globals'

const useDataApi = (firstUrl, initialData, initialRequestJSON = {}, requestMethod = 'GET') => {
  const [url, setURL] = useState(firstUrl)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState('')
  const [data, setData] = useState(initialData)
  const [forceUpdateSwitch, setForceUpdateSwitch] = useState(false)
  const [requestJSON, setRequestJSON] = useState(initialRequestJSON)


  const handleError = (error) => {

    setIsError(true)
    setError(error)
  }

  const setInitials = () => {
    setIsLoading(true)
    setIsError(false)
  }

  useEffect(() => {
    const accessToken = GET_COOKIE('access_token')
    const currentPath = window.location.pathname
    // alert(currentPath)
    if (currentPath !== '/login' && (accessToken === null || accessToken === '')) {
      window.location.href = '/login'
    }

    if (url !== '') {
      setInitials()

      const initData = {
        method: requestMethod,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      }
      if (requestMethod !== 'GET') {
        initData['body'] = JSON.stringify(requestJSON)
      }

      const fetchData = async () => fetch(url, initData)
      console.log(url)
      fetchData()
        .then((response) => {
          if (response.ok) {
            response.json().then(jsonData => {
              console.log('data', jsonData)
              setData(jsonData)
            })
          } else {
            if (response.status === 401) {
              window.location.href = '/login'
            } else {
              handleError({
                'code': response.status,
                'message': response.statusText
              })
            }
          }
        })
        .catch(error => {
          handleError({
            message: 'Could not connect to network'
          })
        }).finally(() => {
        setIsLoading(false)
      })
    }


  }, [url, forceUpdateSwitch, requestJSON])

  return [{ isLoading, isError, error, data, forceUpdateSwitch }, setForceUpdateSwitch, setURL, setRequestJSON]
}


export default useDataApi