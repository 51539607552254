import React from 'react'
import {
  Row,
  Col,
  Card,
  Statistic,
  Icon,
  Divider,
  Typography,
  Skeleton,
  Empty
} from 'antd'
import {
  PERCENTAGE_COLORS,
  BASE_URL,
  SURVEYS_SUMMARY_URL
} from '../../environment/globals'

import useDataApi from '../../effects/fetcher'

import './assets/main.less'

import RetryHomeRow from '../../ui_pieces/retry_home_row'
import ErrorView from '../../ui_pieces/error_view'
import { Link } from '@reach/router'

const { Meta } = Card
const { Title } = Typography
const { Text } = Typography

function SurveySummaryCard({ loading, survey = {} }) {
  const { name, total_questions, total_respondents } = survey

  const surveyClicked = () => {
    sessionStorage.setItem('surveyId', survey.id)

    window.location.href = '/dashboard'
  }

  const percentage_color =
    total_respondents >= 10000
      ? PERCENTAGE_COLORS.high
      : total_respondents >= 1000
      ? PERCENTAGE_COLORS.medium
      : PERCENTAGE_COLORS.low

  return (
    <Col xs={20} md={10} lg={6} className={'surveys mid-padded'}>


      <Card hoverable={!loading} onClick={surveyClicked}>

        <Skeleton loading={loading} active={true}>
          <Divider>
            <Meta
              title={<h1 className="surveys text-center">{name}</h1>}
              style={{ margin: 0 }}
            />
          </Divider>
          <Row type="flex" justify="space-around">
            <Col span={10}>
              <Statistic title="Total Questions" value={total_questions}/>
            </Col>
            <Col span={10}>
              <Statistic
                title="Respondents"
                value={total_respondents}

                valueStyle={{ color: percentage_color }}
                prefix={<Icon type="bar-chart"/>}

              />
            </Col>
          </Row>
        </Skeleton>
      </Card>

    </Col>
  )
}

function Surveys() {
  const [
    { isLoading, isError, error, data, forceUpdateSwitch },
    setForceUpdateSwitch] = useDataApi(BASE_URL + SURVEYS_SUMMARY_URL, [
    [{}, {}, {}],
    [{}, {}, {}]
  ])


  let surveyReviews = null

  console.log(data, 'survey')
  // try to parse data and prepare a view consisting
  // of survey review cards
  try {
    surveyReviews = data.length ? (
      data.map((surveys, index) => {
        return (
          <Row type={'flex'} justify={'center'}>
            {surveys.map((survey, index) => (
              <SurveySummaryCard
                key={index}
                loading={isLoading}
                survey={survey}
              />
            ))}
          </Row>
        )
      })
    ) : (
      <div>
        <Empty description={'No data could be found'}/>
        <RetryHomeRow
          retryHandler={() => setForceUpdateSwitch(!forceUpdateSwitch)}
        />
      </div>
    )
  } catch (e) {
  }

  return (
    <div className="surveys high-margin">
      <Row type={'flex'} justify={'center'} className={'surveys no-margin'}>
        <Title className={''}>SEEP Portal </Title>
      </Row>
      <Row type={'flex'} justify={'center'} className={'surveys no-margin'}>
        <Title level={2} className={''}>
          Browse Surveys{' '}
        </Title>
      </Row>

      <Row type={'flex'} justify={'center'} style={{ marginBottom: '2em' }}>
        <Text strong>
          All the surveys under seep portal with review. Choose one to see
          detail report of the survey.{' '}
        </Text>
      </Row>

      {!isError && surveyReviews}

      {isError && (
        <Row type={'flex'} justify={'center'}>
          <ErrorView
            error={error}
            retryHandler={() => setForceUpdateSwitch(!forceUpdateSwitch)}
          />
        </Row>
      )}
    </div>
  )
}

export default Surveys
