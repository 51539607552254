import useDataApi from '../../effects/fetcher'
import { BASE_URL, SURVEY_TOP_REPORT_URL } from '../../environment/globals'
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Icon, Input,
  Pagination,
  Radio,
  Row,
  Select,
  Skeleton,
  Spin,
  Typography
} from 'antd'
import React, { useEffect, useState } from 'react'
import RetryHomeRow from '../../ui_pieces/retry_home_row'

const { Option } = Select
const { Text } = Typography
const { Search } = Input

function CategoryQuestions({ questionSelected }) {
  return (


    <Row style={{ marginTop: '2em' }}>
      <Col>
        {<Questions questionSelected={questionSelected}/>}
      </Col>
    </Row>


  )
}

function Categories() {

  const [{ isLoading, isError, error, data, forceUpdateSwitch }, setForceUpdateSwitch] =
    useDataApi('http://jfie' + SURVEY_TOP_REPORT_URL, [{}, {}, {}, {}, {}])


  return (
    <Card bordered={false} bodyStyle={{ paddingBottom: 0 }}>
      <Select
        size={'large'}
        loadingg={true}
        allowClear={false}
        showSearch
        style={{ width: 200 }}
        placeholder="Select a person"
        optionFilterProp="children"
        // onChange={onChange}
        // onFocus={onFocus}
        // onBlur={onBlur}
        // onSearch={onSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        <Option value="jack">Jack</Option><Option value="lucy">Lucy</Option>
        <Option value="tom">Tom</Option>
      </Select>
      <Divider style={{ marginBlock: '1em' }}/>
    </Card>

  )
}

function Questions({ questionSelected }) {

  const [{ isLoading, isError, error, data, forceUpdateSwitch }, setForceUpdateSwitch, setURL] =
    useDataApi(BASE_URL + '/api/biweeklies/' + sessionStorage.getItem('surveyId') + '/questions', { data: [] })


  const [filteredData, setFilteredData] = useState(data)

  const [filter: string, setFilter] = useState(null)


  let questionsData = null
  try {
    console.log(filteredData.data[0], data.data[0])
    questionsData = filteredData.data.length ?
      <Radio.Group defaultValue={filteredData.data[0].id} size="large">
        {filteredData.data.map((question, index) => {
          return (
            <div key={question.id} style={{ marginTop: '.5em' }}>
              <Radio.Button style={{ overflow: 'visible', height: 'auto', width: '100%' }}
                            value={question.id}
                            question={question.question}

                            onChange={(event) => {
                              questionSelected({
                                id: question.id,
                                short_form: question.short_form,
                                name: question.question
                              })

                            }}> {question.short_form}</Radio.Button>
            </div>
          )
        })}

      </Radio.Group> :

      <Row justify={'center'} type={'vertical'}>
        <Empty description={'No questions could be found for the survey'}/>
        <RetryHomeRow
          retryHandler={() => setForceUpdateSwitch(!forceUpdateSwitch)}
          showHome={false}

        />
      </Row>

  } catch (e) {

  }


  useEffect(() => {


    // filter questions
    if (filter === '' || filter == null) {
      setFilteredData(data)
    } else {
      setFilteredData({ data: [] })

      const tempFilterData = []

      data.data.forEach(question => {

        if (question.short_form.toLowerCase().includes(filter.toLowerCase())) {
          tempFilterData.push(question)
        }
      })
      setFilteredData({ data: tempFilterData })
    }

    if (data.data.length && filter === null) {
      questionSelected({ id: data.data[0].id, short_form: data.data[0].short_form, name: data.data[0].question })
    }

  }, [filter, data])


  return (

    <Card bordered={false} bodyStyle={{ paddingTop: 0 }}>
      <Row>
        <Search
          placeholder="filter questions"
          onChange={(e) => data.data.length && setFilter(e.target.value)
          }
        />
      </Row>
      <Row>
        <div style={{ marginTop: '1em', marginBottom: '1em' }}>
          <Text type={'secondary'} strong={true}> Select a question to visualize data</Text>
        </div>

        <Spin size={'large'} style={{ marginTop: '1em', marginBottom: '1em' }} spinning={isLoading}
              indicator={<Icon type={'loading'} spin/>}
        >
          <Skeleton loading={isLoading} title={0} paragraph={{ rows: 4 }}>
            {!isError && questionsData}
          </Skeleton>

        </Spin>

        {isError && <Button type={'link'} icon={'reload'} onClick={() => setForceUpdateSwitch(!forceUpdateSwitch)}
                            title={'Reload questions'}> Reload Questions</Button>
        }
      </Row>
      <Row>
        <div style={{ marginTop: '2em' }}>
          {
            data.total > 0 &&
            <Pagination size={'small'} defaultCurrent={1} current={data.current_page} total={data.total}
                        pageSize={data.per_page}
                        onChange={(page) => {
                          setURL(BASE_URL + '/api/biweeklies/' + sessionStorage.getItem('surveyId') + '/questions?page=' + page)
                        }}/>}
        </div>
      </Row>


    </Card>)

}

export default CategoryQuestions