import { Alert, Card, Icon, Row, Spin, Statistic, Typography } from 'antd'
import React, { useEffect } from 'react'
import useDataApi from '../../effects/fetcher'
import PieChart from 'highcharts-react-official'
import HighchartsReact from 'highcharts-react-official'
import {
  BASE_URL,
  SURVEY_GET_AGGREGATE_QUESTION_REPORT,
  SURVEY_SEGMENTATION_FILTER_REPORT_URL,
  SURVEY_SEGMENTATION_REPORT_URL
} from '../../environment/globals'
import Highcharts from 'highcharts'

const { Meta } = Card
const { Title, Text } = Typography

function SegmentationReport({ selectedQuestion, segmentationQuestion, segmentationFilters, aggregateData }) {

  const [{ isLoading, isError, error, data, forceUpdateSwitch }, setForceUpdateSwitch, setURL] =
    useDataApi(BASE_URL + SURVEY_SEGMENTATION_REPORT_URL, [{}, {}, {}, {}, {}])


  useEffect(() => {


    if (selectedQuestion && selectedQuestion.id > 0 && segmentationQuestion && segmentationQuestion > 0) {

      if (segmentationFilters != null) {

        let url_encoded_filters = ''
        segmentationFilters.forEach((item, index) => {
          url_encoded_filters += '&filters[]=' + item
        })
        setURL(BASE_URL + SURVEY_SEGMENTATION_FILTER_REPORT_URL + '/' + selectedQuestion.id + '/' + segmentationQuestion + '?' + url_encoded_filters)

      } else {

        setURL(BASE_URL + SURVEY_SEGMENTATION_REPORT_URL + '/' + selectedQuestion.id + '/' + segmentationQuestion)

      }

    } else if (aggregateData.questions.length) {
      let url_encoded_aggregated_questions = ''
      aggregateData.questions.forEach((item, index) => {
        url_encoded_aggregated_questions += '&aggregated_questions[]=' + item
      })

      setURL(BASE_URL + SURVEY_GET_AGGREGATE_QUESTION_REPORT + '?' + url_encoded_aggregated_questions)
    }


  }, [selectedQuestion, segmentationQuestion, setURL, segmentationFilters, aggregateData])

  const pieChartOptions = {
    chart: {

      type: 'pie'
    },
    title: {
      text: 'Aggregate Questions Result'
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        },
        showInLegend: true
      }
    },
    series: [{
      name: 'Aggregate Questions Result',
      colorByPoint: true,
      data: data.hasOwnProperty('aggregate-report') ? data['aggregate-report'] : []
    }]
  }

  const barChartOptions = {
    chart: {
      type: 'column'
    },
    title: {
      text: selectedQuestion.short_form
    },
    xAxis: {
      categories: data.hasOwnProperty('categories') ? data['categories'] : []
    },
    yAxis: {
      min: 0,
      title: {
        text: selectedQuestion.short_form || 'No question selected'
      }
    },
    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true
    },
    plotOptions: {
      column: {
        stacking: 'percent',
        dataLabels: {
          enabled: true
        }
      }
    },
    series: data.hasOwnProperty('report') ? data['report'] : []
  }

  console.log('segregport', data)


  const HC = () => <div>
    <HighchartsReact
      highcharts={Highcharts}
      options={barChartOptions}
    />
  </div>

  // console.log('agrep', data)
  // const aggregateDataReport = data.hasOwnProperty('respondents') ? (
  //     <Row type={'flex'} style={{ justifyContent: 'center' }}>
  //
  //       <Statistic
  //         title={<Title level={2}> Result </Title>}
  //         value={data['computed']}
  //
  //         style={{ margin: '1.5em', fontSize: '1.2em' }}
  //         valueStyle={{ color: 'lightblue' }}
  //         prefix={<Icon type="bar-chart"/>}
  //
  //       />
  //
  //       <Statistic
  //
  //         title={<Title level={2}> Respondents </Title>}
  //         value={data['respondents']}
  //         style={{ margin: '1.5em', fontSize: '1.2em' }}
  //         valueStyle={{ color: 'lightcoral' }}
  //         prefix={<Icon type="bar-chart"/>}
  //
  //       />
  //       {/*<div style={{ marginTop: '1em' }}>*/}
  //       {/*  <Title> {data} </Title>*/}
  //
  //       {/*</div>*/}
  //
  //       {/*<div>*/}
  //       {/*  <Text type={'secondary'}>Results</Text>*/}
  //       {/*</div>*/}
  //     </Row>
  //   ) :
  //   null


  return (


    <Card bordered={true} color={'blue'} bodyStyle={{ background: 'white' }}

          style={{
            padding: 0,
            borderRadius: '.625rem',
            boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)'
          }}
          title={<Meta

            title={<Title type={'secondary'} level={4} style={{ marginBottom: 0 }}> With Segmentaiton </Title>}
            description={'visualize data based on the above filters'}/>
          }>

      <Spin active size={'large'} spinning={isLoading}>

        <Alert
          message={selectedQuestion.id ? 'Question selected' : 'Select a question'}
          type={selectedQuestion.id ? 'info' : 'warning'}
          description={selectedQuestion.name || 'No statistical information to display'}
          showIcon
        />

        {
          data.hasOwnProperty('aggregate-report') ?
            <PieChart options={pieChartOptions} highcharts={Highcharts}/> :
            selectedQuestion.id && <HC/>
        }
      </Spin>


    </Card>
  )
}

export default SegmentationReport