/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react'
import { enquireScreen } from 'enquire-js'

import Banner5 from './Banner5'
import Feature6 from './Feature6'
import Feature7 from './Feature7'
import Feature0 from './Feature0'
import Feature8 from './Feature8'

import {
  Banner50DataSource,
  Feature60DataSource,
  Feature70DataSource,
  Feature00DataSource,
  Feature80DataSource
} from './data.source'
import './less/antMotionStyle.less'

let isMobile
enquireScreen(b => {
  isMobile = b
})

const { location } = window

export default class LandingHome extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile,
      show: !location.port
    }
  }

  componentDidMount() {

    enquireScreen(b => {
      this.setState({ isMobile: !!b })
    })

    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true
        })
      }, 500)
    }
  }

  render() {
    const children = [
      // <Nav3
      //   id="Nav3_0"
      //   key="Nav3_0"
      //   dataSource={Nav30DataSource}
      //   isMobile={this.state.isMobile}
      // />,
      <Banner5
        id="Banner5_0"
        key="Banner5_0"
        dataSource={Banner50DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature6
        id="Feature6_0"
        key="Feature6_0"
        dataSource={Feature60DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature7
        id="Feature7_0"
        key="Feature7_0"
        dataSource={Feature70DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature0
        id="Feature0_0"
        key="Feature0_0"
        dataSource={Feature00DataSource}
        isMobile={this.state.isMobile}
      />
      //<Feature8
      // id="Feature8_0"
      //  key="Feature8_0"
      /// dataSource={Feature80DataSource}
      // isMobile={this.state.isMobile}
      ///>
      // <Footer1
      //   id="Footer1_0"
      //   key="Footer1_0"
      //   dataSource={Footer10DataSource}
      //   isMobile={this.state.isMobile}
      // />,
    ]
    return (
      <div
        className="templates-wrapper"
        ref={d => {
          this.dom = d
        }}>
        {this.state.show && children}
      </div>
    )
  }
}
