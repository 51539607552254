import React from 'react'
import { GET_COOKIE } from '../../environment/globals'

export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper jzih1dpqqrg-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo jzjgnya1gmn-editor_css',
    children: 'logo_without_text.jpg'
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '/',
          children: [
            {
              children: (
                <>
                  <h3>Home</h3>
                </>
              ),
              name: 'text'
            }
          ]
        }
        // subItem: [
        //   {
        //     className: 'item-sub',
        //     children: {
        //       className: 'item-sub-item jzj8295azrs-editor_css',
        //       children: [
        //         {
        //           name: 'image0',
        //           className: 'item-image jzj81c9wabh-editor_css',
        //           children:
        //             'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*4_S6ToPfj-QAAAAAAAAAAABkARQnAQ'
        //         }
        //       ]
        //     },
        //     name: 'sub~jzj8hceysgj'
        //   }
        // ]
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '/surveys',
          children: [
            {
              children: (
                <>
                  <h3>Surveys</h3>
                </>
              ),
              name: 'text'
            }
          ]
        }
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '/login',
          children: [
            {
              children: (
                <>
                  {GET_COOKIE('email') ? (
                    <h3> Log out <span style={{ fontSize: '0.9em' }}> ( {GET_COOKIE('email')} ) </span></h3>) : (
                    <h3>Login</h3>)}
                </>
              ),
              name: 'text'
            }
          ]
        }
      }
    ]
  },
  mobileMenu: { className: 'header3-mobile-menu' }
}
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      { name: 'title', children: 'SEEP Portal', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: 'Surveys Assessment'
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: 'With the most popular assessment techniques'
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '/surveys',
          className: 'banner5-button',
          type: 'primary',
          children: 'Browse Surveys'
        }
      }
    ]
  },
  image: {
    className: 'banner5-image',
    children:
      'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*-wAhRYnWQscAAAAAAAAAAABkARQnAQ'
  }
}
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' }
      },
      title: { className: 'feature6-title' }
    },
    children: [
      {
        title: { className: 'feature6-title-text', children: 'STATS' },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'surveys' },
              children: '6'
            },
            children: { className: 'feature6-text', children: 'available' }
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'questions' },
              children: '2,000 +'
            },
            children: { className: 'feature6-text', children: 'asked' }
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: 'respondents' },
              children: '500+'
            },
            children: { className: 'feature6-text', children: 'participated' }
          }
        ]
      },
      {
        title: { className: 'feature6-title-text', children: 'Surveys' },
        className: 'feature6-item',
        name: 'block1',
        children: [
          {
            md: 8,
            xs: 24,
            name: 'child0',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '万' },
              children: '116'
            },
            children: { className: 'feature6-text', children: 'Countries' }
          },
          {
            md: 8,
            xs: 24,
            name: 'child1',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '亿' },
              children: '1.17'
            },
            children: { className: 'feature6-text', children: 'Average age' }
          },
          {
            md: 8,
            xs: 24,
            name: 'child2',
            className: 'feature6-number-wrapper',
            number: {
              className: 'feature6-number',
              unit: { className: 'feature6-unit', children: '亿' },
              children: '2.10'
            },
            children: { className: 'feature6-text', children: 'Researcher' }
          }
        ]
      }
    ]
  }
}
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: 'WHAT YOU FOUND IN THIS PORTAL'
      },
      {
        name: 'content',
        className: 'feature7-title-content',
        children: '--------------------------------------------------------------------------------'
      }
    ]
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg'
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: 'PORTAL 1'
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: 'L-IFT BV and  L-IFT Myanmar Co Ltd is the main implementer.'
            }
          ]
        }
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg'
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: 'PORTAL 2'
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: 'L-IFT BV and  L-IFT Myanmar Co Ltd is the main implementer.'
            }
          ]
        }
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg'
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: 'PORTAL 3'
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: 'L-IFT BV and  L-IFT Myanmar Co Ltd is the main implementer.'
            }
          ]
        }
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg'
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: 'PORTAL 4'
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: 'L-IFT BV and  L-IFT Myanmar Co Ltd is the main implementer.'
            }
          ]
        }
      },
      {
        md: 6,
        xs: 24,
        name: 'block4',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg'
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: 'PORTAL 5'
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: 'L-IFT BV and  L-IFT Myanmar Co Ltd is the main implementer.'
            }
          ]
        }
      },
      {
        md: 6,
        xs: 24,
        name: 'block5',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg'
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: 'PORTAL 6'
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: 'L-IFT BV and  L-IFT Myanmar Co Ltd is the main implementer.'
            }
          ]
        }
      },
      {
        md: 6,
        xs: 24,
        name: 'block6',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg'
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: 'PORTAL 7'
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: 'L-IFT BV and  L-IFT Myanmar Co Ltd is the main implementer.'
            }
          ]
        }
      },
      {
        md: 6,
        xs: 24,
        name: 'block7',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg'
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: 'PORTAL 8'
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: 'L-IFT BV and  L-IFT Myanmar Co Ltd is the main implementer.'
            }
          ]
        }
      }
    ]
  }
}
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: 'Partners' }]
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'jzjn8afnsxb-editor_css content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item jzjgrrupf2c-editor_css',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon jzjgrlz134-editor_css',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ'
            }

          ]
        }
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon jzjncn210ql-editor_css',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ'
            }

          ]
        }
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon jzjndq0dueg-editor_css',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ'
            }

          ]
        }
      },
      {
        name: 'block~jzjn87bmyc7',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon jzjndsyw8sf-editor_css',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*CTp8T7RT-VkAAAAAAAAAAABkARQnAQ'
            }

          ]
        }
      }
    ]
  }
}
export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      { name: 'title', className: 'feature8-title-h1', children: '使用流程' },
      {
        name: 'content',
        className: 'feature8-title-content',
        children: '流程简单清晰，快速响应需求'
      }
    ]
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: { href: '#', children: '立即体验' }
      }
    ]
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' }
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: '平台自主训练流程'
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg'
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg'
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通'
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '沟通业务需求，对接人：诚凡、芸彩'
                  }
                ]
              }
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg'
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg'
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通'
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩'
                  }
                ]
              }
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg'
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg'
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通'
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩'
                  }
                ]
              }
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg'
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg'
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通'
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩'
                  }
                ]
              }
            }
          ]
        },
        {
          name: 'block1',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            children: '平台自主训练流程',
            className: 'feature8-carousel-title-block'
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg'
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg'
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通'
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: '沟通业务需求，对接人：诚凡、芸彩'
                  }
                ]
              }
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg'
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg'
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通'
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩'
                  }
                ]
              }
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg'
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg'
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通'
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩'
                  }
                ]
              }
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg'
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg'
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: '需求沟通'
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children:
                      '沟通业务需求，对接人：诚凡、芸彩沟通业务需求，对接人：诚凡、芸彩'
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  }
}
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          className: 'logo jzl0qcpyjra-editor_css',
          children:
            'L-IFT BV | BRAC'
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <>
                  <p>Contact us</p>
                </>
              )
            }
          ]
        }
      },
      {
        name: 'block2',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          children: (
            <>
              <p>More products</p>
            </>
          )
        },
        childWrapper: {
          children: [
            {
              name: 'image~jzl0tcm4f1d',
              className: '',
              children:
                'https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*NoENTI5uyn4AAAAAAAAAAABkARQnAQ'
            },
            {
              href: '#',
              name: 'link0',
              children: (
                <>
                  <p>LiFT company</p>
                </>
              ),
              className: 'jzl0u1bko6-editor_css'
            },
            { href: '#', name: 'link1', children: 'Surveys' }
          ]
        }
      },
      {
        name: 'block3',
        xs: 24,
        md: 8,
        className: 'block',
        title: { children: 'About' },
        childWrapper: {
          children: [
            { href: '#', name: 'link0', children: 'Surveys' },
            { href: '#', name: 'link1', children: 'LiFT' }
          ]
        }
      }
    ]
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <>
        &nbsp;&nbsp;Copyright © 2019 my.l-ift.com
        <br/>
      </>
    )
  }
}
