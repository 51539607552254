import { Card, Col, message, Row, Tabs, Typography } from 'antd'
import React, { useState } from 'react'
import TopReports from './top_reports'
import CategoryQuestions from './category_questions'
import SegmentationFilters from './segmentations'
import SegmentationReport from './segmentation_report'
import NoSegmentationReport from './no_segmentation_report'
import './assets/main.css'
import AggregateQuestion from './aggregate_questions'

const { Title, Text } = Typography

const { TabPane } = Tabs

function Dashboard() {
  const [filterVisible, setFilterVisible] = useState(true)
  const [selectedQuestion, setSelectedQuestion] = useState({})
  const [segmentationQuestion, setSegmentationQuestion] = useState(null)
  const [segmentationFilters, setSegmentationFilters] = useState([])
  const [aggregateData, setAggregateData] = useState({ questions: [], computation: 1 })

  const switchFilter = (value) => {
    setFilterVisible(value)
  }
  const questionSelected = (question) => {
    setSelectedQuestion(question)
  }
  const segmentationQuestionSelected = (question) => {
    setSegmentationQuestion(question)
  }

  const filterSegmentations = (filters) => {
    if (filters.length !== segmentationFilters.length) {
      setSegmentationFilters(filters)
    } else {
      filters.forEach((item, index) => {
        if (item !== segmentationFilters[index]) {

          setSegmentationFilters(filters)
        }
      })
    }
  }

  const calculateAggregate = (questions, computation) => {
    if (questions.length >= 2) {

      questionSelected({
        name: `${questions.length} numeric questions selected`,
        short_form: `${questions.length} numeric questions selected`,
        id: -1
      })
      setAggregateData({
        questions: questions,
        computation: computation
      })
    } else {
      message.warning('Make sure 2 more or numeric question is selected!')
    }
  }


  return (


    <div style={{ background: '#f5f6f8' }}>


      <Row type={'flex'} justify={'space-around'} style={{ marginTop: '2em', marginBottom: '2em' }}>
        <Col span={24}>
          <Card bordered={false}>
            <Text level={4} secondary={true}> Dashboard </Text>
            <Title level={2} style={{ marginTop: 0 }}> Survey Overview </Title></Card>
        </Col>

        <Col span={24}>
          <TopReports/>
        </Col>


      </Row>

      <Row type={'flex'} justify={'space-between'}>
        <Col span={6}>
          <Tabs defaultActiveKey={1}>

            <TabPane tab={<span>Normal Questions</span>} key={1}>
              <CategoryQuestions questionSelected={questionSelected}/>
            </TabPane>
            <TabPane tab={<span>Aggregate Questions</span>} key={2}>
              <AggregateQuestion calculateAggregate={calculateAggregate}/>
            </TabPane>

          </Tabs>
        </Col>

        <Col span={18}>


          <Row type={'flex'} justify={'space-between'} style={{ margin: '3em' }}>
            <Col span={filterVisible ? 16 : 22}>


              {<SegmentationReport selectedQuestion={selectedQuestion} segmentationQuestion={segmentationQuestion}
                                   segmentationFilters={segmentationFilters} aggregateData={aggregateData}/>}

              {<NoSegmentationReport selectedQuestion={selectedQuestion}/>}

            </Col>
            <Col span={filterVisible ? 8 : 2}>

              {<SegmentationFilters switchFilter={switchFilter}
                                    segmentationQuestionSelected={segmentationQuestionSelected}
                                    filterSegmentations={filterSegmentations}/>}


            </Col>
          </Row>
        </Col>

      </Row>


    </ div>

  )
}

export default Dashboard