import { Alert, Card, Row, Spin, Statistic, Typography } from 'antd'
import React, { useEffect } from 'react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import PieChart from 'highcharts-react-official'
import useDataApi from '../../effects/fetcher'
import { BASE_URL, SURVEY_NO_SEGMENTATION_REPORT_URL } from '../../environment/globals'


const { Meta } = Card
const { Title, Text } = Typography

function NoSegmentationReport({ selectedQuestion }) {

  const [{ isLoading, isError, error, data, forceUpdateSwitch }, setForceUpdateSwitch, setURL] =
    useDataApi(BASE_URL + SURVEY_NO_SEGMENTATION_REPORT_URL, [])


  useEffect(() => {
    console.log(selectedQuestion)
    if (selectedQuestion && selectedQuestion.id > 0) {
      console.log('j', selectedQuestion)

      setURL(BASE_URL + SURVEY_NO_SEGMENTATION_REPORT_URL + '/' + selectedQuestion.id)
    }
  }, [setURL, selectedQuestion])
  const options = {
    chart: {

      type: 'pie'
    },
    title: {
      text: selectedQuestion.name || ''
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        },
        showInLegend: true
      }
    },
    series: [{
      name: selectedQuestion.name,
      colorByPoint: true,
      data: data.hasOwnProperty('report') ? data['report'] : []
    }]
  }


  return (

    <Card style={{ marginTop: '2em' }} bordered={true} color={'blue'} bodyStyle={{ background: 'white' }}
          title={<Meta
            title={<Title type={'secondary'} level={4} style={{ marginBottom: 0 }}> Without Segmentaiton </Title>}
            description={'visualize data not based on any segmentation'}
          />}>

      <Row type={'flex'} justify={'center'}>

        <Spin active size={'large'} spinning={isLoading}>

        </Spin>
      </Row>

      {isLoading &&
      <Alert
        message={'Question did not load '}
        type='warning'
        description='No question is selected or the report could not be loaded'
        showIcon
      />
      }

      {!isLoading && selectedQuestion.id && (selectedQuestion.id > 0 ?
        <PieChart options={options} highcharts={Highcharts}/> :
        <Text type={'secondary'}> No data for numeric questions </Text>)}


    </Card>
  )
}

export default NoSegmentationReport