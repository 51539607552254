import useDataApi from '../../effects/fetcher'
import { BASE_URL, SURVEY_SEGMENTATIONS_URL } from '../../environment/globals'
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Empty,
  Icon,
  Row,
  Select,
  Skeleton,
  Spin,
  Switch,
  Typography
} from 'antd'
import RetryHomeRow from '../../ui_pieces/retry_home_row'
import React, { useEffect, useState } from 'react'

const { Title } = Typography
const { Option } = Select

function SegmentationFilters({ switchFilter, segmentationQuestionSelected, filterSegmentations }) {

  const [{ isLoading, isError, error, data, forceUpdateSwitch }, setForceUpdateSwitch] =
    useDataApi(BASE_URL + SURVEY_SEGMENTATIONS_URL, [{}, {}, {}, {}, {}])

  const [{ isLoading: segQuesIsLoading, isError: segQuesIsError, error: segQuesError, data: segQuesData, forceUpdateSwitch: segQiesforceUpdateSwitch },
    segQuesSetForceUpdateSwitch, segQuesSetURL] = useDataApi(BASE_URL + '/api/biweeklies/' + sessionStorage.getItem('surveyId') + '/segmentation-questions', { biweekly_questions: [] })

  const [selectedFilters, setSelectedFilters] = useState({})


  const [visible, setVisible] = useState(true)

  let setgmentationFilters = null
  let segmentationQuestionData = null
  let handleFilterChanged = (values, groupName) => {

    let newSelectedFilters = selectedFilters

    newSelectedFilters[groupName] = values
    setSelectedFilters(newSelectedFilters)

  }
  let handleFilterSegmentations = () => {
    let filters = []
    for (const filter in selectedFilters) {
      if (selectedFilters.hasOwnProperty(filter)) {
        selectedFilters[filter].forEach((item, index) => {
          filters.push(item)
        })
      }
    }
    filterSegmentations(filters)
  }


  let handleSwitchChanged = (value) => {
    setVisible(value)
    switchFilter(value)
  }

  useEffect(() => {

    if (segQuesData.biweekly_questions && segQuesData.biweekly_questions.length
  )
    {

      segmentationQuestionSelected(segQuesData.biweekly_questions[0].id)
    }


  }, [segQuesData])

  try {
    setgmentationFilters = data.length ?
      data.map((segmentation, index) => {
        const options = []
        if (segmentation.hasOwnProperty('values')) {
          segmentation.values.forEach((segmentationValue, index) =>
            options.push({ label: segmentationValue.value, value: segmentationValue.id })
          )
        }
        return (
          <Col span={24} key={index} style={{ marginBottom: '1em' }}>

            <Card loading={isLoading} style={{ margin: '.4em' }} bodyStyle={{ padding: '.3em' }} bordered={false}
            >
              <Title level={4} type={'secondary'}> {segmentation.name} </Title>
              <Checkbox.Group options={options}
                              onChange={(data) => handleFilterChanged(data, segmentation.name)}
              />
            </Card>


          </Col>
        )
      })
      : <Row type={'flex'} justify={'center'}>
        <Empty description={'No segmentation could be found, try again'}/>
        <RetryHomeRow
          retryHandler={() => setForceUpdateSwitch(!forceUpdateSwitch)}
          showHome={false}

        />
      </Row>


  } catch (e) {

  }

  try {
    segmentationQuestionData = segQuesData.biweekly_questions.length ?
      <Select loading={segQuesIsLoading} size={'large'}
              defaultValue={segQuesData.biweekly_questions[0].id}
              placeholder={'Select a segmentation question'}
              style={{ width: '20rem' }}

              onChange={(questionId) => {
                segmentationQuestionSelected(questionId)
              }}

      >
        {
          segQuesData.biweekly_questions.map((segmentationQuestion, index) => {
            return (
              <Option key={segmentationQuestion.id} value={segmentationQuestion.id}
                      title={segmentationQuestion.question}>
                {segmentationQuestion.short_form}
              </Option>
            )

          })
        }
      </Select>

      :
      segQuesIsLoading ?
        <Spin spinning={true} tip={'loading segmentation questions'} style={{ marginTop: '1em' }}/> : null


  } catch (e) {

  }


  return (

    <Card style={{
      marginLeft: '2em',
      borderRadius: '.625rem',
      boxShadow: '0 2px 0 rgba(90,97,105,.11), 0 4px 8px rgba(90,97,105,.12), 0 10px 10px rgba(90,97,105,.06), 0 7px 70px rgba(90,97,105,.1)'
    }}

    >
      <Row type={'flex'} justify={'space-between'}>
        {
          visible &&
          <Col>
            <Button type={'primary'} onClick={() => handleFilterSegmentations()}> Filter </Button>
          </Col>
        }
        <Col>
          <Switch
            title={'Filter'} defaultChecked={visible} onChange={value => handleSwitchChanged(value)}/>
        </Col>
      </Row>

      {visible && <Divider dashed={true}/>}

      {
        visible && !segQuesIsError && segmentationQuestionData

      }
      {
        visible && segQuesIsError &&
        <Alert message={'Some error occurred loading survey segemntation questions '} type={'error'}/>
      }

      {visible && <Divider dashed={true}/>}


      < Spin
        size={'large'}
        style={
          {
            marginTop: '1em', marginBottom:
              '1em'
          }
        }
        spinning={isLoading}
        indicator={
          <Icon type={'loading'} spin/>
        }>

        <
          Skeleton
          loading={isLoading}>
          {visible &&


          <Row type={'flex'} justify={'center'} align={'top'} style={{ marginBottom: '1em' }}>

            {
              !isError && setgmentationFilters
            }
            {isError &&
            <Button type={'link'} icon={'reload'} onClick={() => setForceUpdateSwitch(!forceUpdateSwitch)}
                    title={'reload segmentation filters'}> Reload Segmentation Filters</Button>}
          </Row>
          }
        </Skeleton>
      </Spin>
    </Card>


  )
}

export default SegmentationFilters
